<template>
  <fieldset>
    <div class="row justify-content-center pt-2 d-none d-lg-flex hidden-md-and-down">
      <div class="col-10 col-sm-7 col-md-6 col-lg-10 col-xl-10">
        <div class="row justify-content-center">
          <div class="text-center">
            <img src="../../../public/avatar_58.png" class="rounded image-icon" />
          </div>
        </div>
      </div>
    </div>
    <div id="cards_row_form" class="row justify-content-center pt-4">
      <div class="col-10 col-sm-10 col-md-10 col-lg-6 col-xl-6">
        <div class="row justify-content-center">
          <p class="text-center slogan2">Cambio de Marcha</p>
        </div>

        <div class="row justify-content-center text-center mt-3">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="card-columns">
                <div class="card" :class="{ active: getMarcha() == 'Manual' }" @click="setMarcha('Manual')">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold">Manual</span>
                    </div>
                  </div>
                </div>
                <div class="card" :class="{ active: getMarcha() == 'Automático' }" @click="setMarcha('Automático')">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold">Automático</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <div class="row justify-content-center pt-2">
    <div id="sixth_row_form" class="row justify-content-center pt-5 pb-4">
      <div id="boton_continuar" class="col-12">
        <button :disabled="!buttonEnabled()" @click="saveDatosCoche()" class="btn-orange center mb-4">
          Finalizar
          <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <cardsFormularioVue />
  <div class="row justify-content-center pt-2">
    <div id="sixth_row_form" class="row justify-content-center pt-5 pb-4">
      <div id="boton_continuar" class="col-12">
        <button :disabled="!buttonEnabled()" @click="saveDatosCoche()" class="btn-orange center mb-4">
          Finalizar
          <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import cardsFormularioVue from "./cardsFormulario.vue";
import axios from 'axios';

export default {
  name: "Step4FalloApi",
  store,
  components: {
    cardsFormularioVue,
  },
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    preload: (state) => state.preload,
    api_url: (state) => state.api_url
  }),
  data: function () {
    return {
      codigo: "",
      maxPrestar: "",
      marcas: [],
      modelos: [],
      years: [],
      windowWidth: window.innerWidth,
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
      },
    };
  },
  mounted() {
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "azul");
    store.commit("setCheckColor2", "azul-2");
    store.commit("setBarraColor2", "azul");
    store.commit("setCheckColor3", "azul-3");
    const menu = document.querySelector('#navigation_menu');
    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }
    window.addEventListener('resize', this.getWindowWidth);
  },
  methods: {
    saveDatosCoche: function(){
            this.formulario.loading = true;
            axios.post(this.api_url+'update/formulario/solicitud/coche/'+store.state.id_solicitud, {
                'car_brand': this.formulario.data_matricula.marca,
                'car_model': this.formulario.data_matricula.modelo,
                'car_year_registration': this.formulario.data_matricula.fecha_matriculacion,
                'potencia': this.formulario.data_matricula.potencia,
                'fuel': this.formulario.data_matricula.combustible,
                'gear': this.formulario.data_matricula.transmision,
                'puertas': this.formulario.data_matricula.puertas,
            })
            .then((resp) => {
                this.formulario.loading = false;
                if(resp.data.error){
                    store.commit('setStep', 10);
                }else{
                    store.commit('setStep', 5);
                    this.$router.push({path: '/formulario/'+store.state.token_enlace, hash: null});   
                }
                window.scrollTo(0, 0);
            });
        },
    buttonEnabled() {
      return this.formulario.data_matricula.transmision != null;
    },

    getMarcha() {
      return this.formulario.data_matricula.transmision;
    },
    setMarcha(respuesta) {
      this.formulario.data_matricula.transmision = respuesta;
      this.transicion = true;
      setTimeout(() => {
        //store.commit("setStepFallo", 3);
      }, 400);
    },
    goTo(route, hash) {
      this.$router.push({ path: route, hash: hash });
    },
    setStep: function () {
      store.commit("setStepFallo", 2);
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    windowWidth(value) {
      console.log(value)
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    }
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
// @include media-breakpoint-only(md) {
//   #navigation_menu {
//     display: none;
//   }
// }

// @include media-breakpoint-only(sm) {
//   #navigation_menu {
//     display: none;
//   }
// }
// @include media-breakpoint-only(xs) {
//   #navigation_menu {
//     display: none;
//   }
// }
#footer-mobile {
  // No queremos que se vea el footer de primeras en mobile, añadiremos un margin adaptado a cada pantalla del form
  margin-top: 0%;
}
</style>
<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

#step1 {
  height: 100%;
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }

  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }

  #cards_row_form {
    margin-top: 20px;
  }

  .card {
    background-color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    &:hover {
      background-color: #004680;
      color: white;
      border: 2px solid rgba(196, 196, 196, 0.4);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      cursor: pointer;
    }
    &:active {
      background-color: #004680;
      color: white;
      border: 2px solid rgba(196, 196, 196, 0.4);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
  }
  .active {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}

@include media-breakpoint-only(xs) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 20px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      padding: 3%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
    .card:hover {
      padding: 3%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 20px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      padding: 3%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
    .card:hover {
      padding: 3%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .columna-centro {
    margin-left: 55%;
  }
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 28px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  .columna-centro {
    margin-left: 55%;
  }
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 28px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
  }
}

@include media-breakpoint-only(xl) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  .columna-centro {
    margin-left: 55%;
  }
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 28px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
  }
}
</style>
