<template>
  <div id="step1">
    <div id="first_row" class="row justify-content-center align-items-center">
      <PelotitasPasos />
    </div>
    <Step1FalloApiVue v-if="stepsFallo == 1" />
    <Step2FalloApiVue v-if="stepsFallo == 2" />
    <Step3FalloApiVue v-if="stepsFallo == 3" />
    <Step4FalloApiVue v-if="stepsFallo == 4" />

    <div id="volver_row_form" v-if="stepsFallo != 1" class="row justify-content-around pt-4">
      <div class="col-10">
        <p class="text-left"><a class="volver_circle" href="#" @click="back()">&lt;</a></p>
      </div>
    </div>
  </div>
  
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";
import PelotitasPasos from "./PelotitasPasos.vue";
import Step1FalloApiVue from "./Step1FalloApi.vue";
import Step2FalloApiVue from "./Step2FalloApi.vue";
import Step3FalloApiVue from "./Step3FalloApi.vue";
import Step4FalloApiVue from "./Step4FalloApi.vue";

export default {
  name: "FalloAPI",
  components: {
    PelotitasPasos,
    Step1FalloApiVue,
    Step2FalloApiVue,
    Step3FalloApiVue,
    Step4FalloApiVue,
  },
  store,
  computed: mapState({
    stepsFallo: (state) => state.stepsFallo,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    preload: (state) => state.preload,
    api_url: (state) => state.api_url
  }),
  data: function () {
    return {
      codigo: "",
      maxPrestar: "",
      marcas: [],
      modelos: [],
      years: [],
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
      },
    };
  },
  methods: {
    goTo(route, hash) {
      this.$router.push({ path: route, hash: hash });
    },
    back: function () {
      store.commit("setStepFallo", this.stepsFallo - 1);
    },
    setStep: function (step) {
      store.commit("setStep", step);
    },
    setHour: function (hour) {
      this.solicitud.hour = hour;
    },
    calcMaxPrestar: function () {
      axios.post(this.api_url + "get/propuesta/" + this.solicitud.id).then((data) => {
        if (data.data) {
          if (data.data.prestamo_cliente > 0 && this.solicitud.coche_necesita > 0) {
            this.maxPrestar = this.solicitud.coche_necesita > data.data.prestamo_cliente ? data.data.prestamo_cliente : this.solicitud.coche_necesita;
            store.commit("setMaximoPrestamo", this.maxPrestar);
          }
        }
      });
    },
  },
  created: function () {
    store.commit("setStepFallo", 1);
  },
  mounted: function () {
    this.setStep(5);
    this.codigo = this.$route.params.codigo;
    // Si no viene código, redirigir al primer paso

    if (isNaN(this.codigo) || this.codigo == "") {
      this.setStep(1);
      this.$router.push({ path: "/", hash: null });
    } else {
      // Buscar id solicitud
      var idSolicitud = this.codigo.slice(14);
      store.commit("setTokenEnlace", this.codigo);
      store.commit("setIdSolicitud", idSolicitud);
    }
    store.commit("setPreload", false);
    this.formulario.matricula = this.solicitud.coche_matricula != undefined ? this.solicitud.coche_matricula.split("") : [""];
    this.formulario.data_matricula.marca = "";
    this.formulario.data_matricula.modelo = "";
    this.formulario.data_matricula.fecha_matriculacion = this.solicitud.coche_matriculacion;
    this.formulario.data_matricula.potencia = this.solicitud.coche_potencia;
  },
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

fieldset {
  margin: 5vh 15% 5vh 15%;
  padding: 2%;
  border: 1px #b3dcff solid;
}
.autocompletar:deep() .simple-typeahead > input[data-v-f81ca714] {
  width: 100%;
  padding: 10px 20px;
  height: 48px;
  line-height: 22px;
  font-size: 18px;
  border-radius: 6px;
  background: white;
  border: solid 1px #c4c4c4;
  opacity: 0.6;
}

#step1 {
  height: 100%;
  .select,
  .ibn_input {
    width: 100%;
    padding: 10px 20px;
    height: 48px;
    line-height: 22px;
    font-size: 18px;
    border-radius: 6px;
    background: white;
    border: solid 1px #c4c4c4;
    opacity: 0.6;
  }
  .checkbox_label {
    display: inline;
    align-items: center;
    color: #004680;
    font-size: small;
  }
  .checkbox {
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #004680;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .checkbox:checked {
    background-image: url("../../assets/img/checked_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }
  .card {
    background-color: white;
    border: none;
  }
  .verMas {
    color: #ffa500;
    font-weight: 600;
    cursor: pointer;
  }
  .feature {
    font-family: "Montserrat-Bold";
    font-size: 14px;
  }
}

@include media-breakpoint-only(xs) {
  #cards_row_form {
    margin-top: 0px !important;
  }
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}
@include media-breakpoint-only(sm) {
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}

@include media-breakpoint-only(md) {
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(lg) {
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(xl) {
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}
</style>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: none;
  }
}
@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none;
  }
}
</style>
<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
.volver_circle {
  height: 35px;
  width: 35px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: solid 1px #dadada;
  border-radius: 50%; /* may require vendor prefixes */
  background: #fff;
}

#step1 {
  height: 100%;
  .select,
  .ibn_input {
    width: 100%;
    padding: 10px 20px;
    height: 48px;
    line-height: 22px;
    font-size: 18px;
    border-radius: 6px;
    background: white;
    border: solid 1px #c4c4c4;
    opacity: 0.6;
  }
  .checkbox_label {
    display: inline;
    align-items: center;
    color: #004680;
    font-size: small;
  }
  .checkbox {
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #004680;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .checkbox:checked {
    background-image: url("../../assets/img/checked_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }
  .card {
    background-color: white;
    border: none;
  }
  .verMas {
    color: #ffa500;
    font-weight: 600;
    cursor: pointer;
  }
  .feature {
    font-family: "Montserrat-Bold";
    font-size: 14px;
  }
}

@include media-breakpoint-only(xs) {
  #cards_row_form {
    margin-top: 0px !important;
  }
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}
@include media-breakpoint-only(sm) {
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}

@include media-breakpoint-only(md) {
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(lg) {
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(xl) {
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}
</style>
