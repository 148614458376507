<template>
  <fieldset :class="{ cambio_paso: transicion }">
    <div class="row justify-content-center pt-2 d-none d-lg-flex hidden-md-and-down">
      <div class="col-10 col-sm-7 col-md-6 col-lg-10 col-xl-10">
        <div class="row justify-content-center">
          <div class="text-center">
            <img src="../../../public/avatar_58.png" class="rounded image-icon" />
          </div>
        </div>
      </div>
    </div>
    <div id="cards_row_form" class="row justify-content-center pt-4">
      <div class="col-10 col-sm-10 col-md-10 col-lg-6 col-xl-6">
        <div class="row justify-content-center">
          <p class="text-center slogan2">Número de Puertas</p>
        </div>

        <div class="row justify-content-center text-center mt-3">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="card-columns">
                <div class="card" :class="{ active: getPuertas() == '3' }" @click="setPuertas('3')">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold">3</span>
                    </div>
                  </div>
                </div>
                <div class="card" :class="{ active: getPuertas() == '4' }" @click="setPuertas('4')">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold">4</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center text-center mt-lg-3 mt-xl-3 mt-0 mt-sm-0 mt-md-0">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="card-columns">
                <div class="card columna-centro" :class="{ active: getPuertas() == '5' }" @click="setPuertas('5')">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold">5</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "Step3FalloApi",

  store,
  computed: mapState({
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    preload: (state) => state.preload,
  }),
  data: function () {
    return {
      codigo: "",
      maxPrestar: "",
      marcas: [],
      modelos: [],
      years: [],
      windowWidth: window.innerWidth,
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
      },
    };
  },
  mounted() {
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "azul");
    store.commit("setCheckColor2", "azul-2");
    store.commit("setBarraColor2", "azul");
    store.commit("setCheckColor3", "blanco");
    const menu = document.querySelector('#navigation_menu');
    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }
    window.addEventListener('resize', this.getWindowWidth);
  },
  methods: {
    comprobarYear() {
      if (this.formulario.data_matricula.fecha_matriculacion == "") {
        this.errors.fecha = true;
        document.getElementById("fecha").style.border = "1px solid #ff000080";
        document.getElementById("fecha").style.borderLeft = "7px solid #ff000080";
        return false;
      } else {
        this.errors.fecha = false;
        document.getElementById("fecha").style.border = "1px solid green";
        document.getElementById("fecha").style.borderLeft = "7px solid green";
        return true;
      }
    },

    nextStepFallo() {
      if (this.buttonEnabled()) {
        store.commit("setStepFallo");
      }
    },
    getPuertas() {
      return this.formulario.data_matricula.puertas;
    },
    setPuertas(respuesta) {
      this.formulario.data_matricula.puertas = respuesta;
      this.transicion = true;
      setTimeout(() => {
        store.commit("setStepFallo", 4);
      }, 400);
    },
    goTo(route, hash) {
      this.$router.push({ path: route, hash: hash });
    },
    setStep: function () {
      store.commit("setStepFallo", 2);
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    windowWidth(value) {
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    }
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
// @include media-breakpoint-only(md) {
//   #navigation_menu {
//     display: none;
//   }
// }

// @include media-breakpoint-only(sm) {
//   #navigation_menu {
//     display: none;
//   }
// }
// @include media-breakpoint-only(xs) {
//   #navigation_menu {
//     display: none;
//   }
// }
#footer-mobile {
  // No queremos que se vea el footer de primeras en mobile, añadiremos un margin adaptado a cada pantalla del form
  margin-top: 85%;
}
</style>
<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

#step1 {
  height: 100%;
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }

  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }

  #cards_row_form {
    margin-top: 20px;
  }

  .card {
    background-color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    &:hover {
      background-color: #004680;
      color: white;
      border: 2px solid rgba(196, 196, 196, 0.4);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      cursor: pointer;
    }
    &:active {
      background-color: #004680;
      color: white;
      border: 2px solid rgba(196, 196, 196, 0.4);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
  }
  .active {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}

@include media-breakpoint-only(xs) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 20px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      padding: 2%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
    .card:hover {
      padding: 2%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 20px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      padding: 2%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
    .card:hover {
      padding: 2%;
      width: 100%;
      height: auto;
      span {
        font-size: 20px;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .columna-centro {
    margin-left: 55%;
  }
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 28px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
    fieldset {
      border: none;
    }
  }
}

@include media-breakpoint-only(lg) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  .columna-centro {
    margin-left: 55%;
  }
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 28px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
  }
}

@include media-breakpoint-only(xl) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  .columna-centro {
    margin-left: 55%;
  }
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 28px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 22px;
      }
    }
  }
}
</style>
