<template>
  <fieldset :class="{ cambio_paso: transicion }">
    <div class="row justify-content-center pt-2 d-none d-lg-flex hidden-md-and-down">
      <div class="col-10 col-sm-7 col-md-6 col-lg-10 col-xl-10">
        <div class="row justify-content-center">
          <div class="text-center">
            <img src="../../../public/avatar_58.png" class="rounded image-icon" />
          </div>
        </div>
      </div>
    </div>
    <div id="fourth_row_form" class="row justify-content-center pt-2">
      <div class="col-11 col-xl-9 mt-md-5 mt-lg-2 mt-sm-1 mt-xl-2">
        <div class="row justify-content-center">
          <p class="text-left slogan1 d-block d-sm-block d-md-none d-lg-none d-xl-none">No hemos podido identificar tu coche por la matrícula. Necesitamos algunos datos para poder avanzar.</p>
          <p class="text-center slogan1 d-none d-sm-none d-md-block d-lg-block d-xl-block pt-xl-3">No hemos podido identificar tu coche por la matrícula. Necesitamos algunos datos para poder avanzar.</p>
        </div>
        <div class="row justify-content-center pt-1 pb-1">
          <p class="text-center slogan2"><b>Datos de tu coche</b></p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-7 col-xl-4 col-md-7 col-sm-8 col-11 p-2 autocompletar">
        <SimpleTypeahead ref="typeAheadMarcas" id="typeahead_marcas" placeholder="Selecciona marca" :items="catalogo_marcas" :minInputLength="1" :defaultItem="this.formulario.data_matricula.marca" @onInput="setMarcaVehiculo($event)" @keyup.down="setMarcaVehiculo($event)" @onBlur="setMarcaVehiculo($event)"> </SimpleTypeahead>
        <p v-if="errors.marca" class="error">Debe rellenar este campo...</p>
      </div>
      <div class="col-lg-7 col-xl-4 col-md-7 col-sm-8 col-11 p-2 autocompletar">
        <SimpleTypeahead ref="typeAheadModelos" id="typeahead_modelos" placeholder="Selecciona modelo" :items="catalogo_modelos" :minInputLength="1" :defaultItem="this.formulario.data_matricula.modelo" @onInput="setModelo($event)" @keyup.down="setModelo($event)" @onBlur="setModelo($event)"> </SimpleTypeahead>
        <p v-if="errors.modelo" class="error">Debe rellenar este campo...</p>
      </div>
      <div class="col-sm-8 col-11 col-md-7 p-2 d-block d-sm-block d-md-block d-lg-none d-xl-none autocompletar">
        <input type="number" id="fecha" min="1999" :max="new Date().getFullYear()" class="ibn_input" placeholder="Año Matriculacion" v-model="formulario.data_matricula.fecha_matriculacion" @blur="comprobarYear()" />
        <p v-if="errors.fecha" class="error">Debe rellenar este campo...</p>
      </div>
    </div>

    <div class="row mt-lg-3 mt-xl-3 mt-md-0 mt-sm-0">
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-xl-4 p-2 d-none d-sm-none d-md-none d-lg-block d-xl-block autocompletar">
            <input type="number" id="fecha" min="1999" :max="new Date().getFullYear()" class="ibn_input" placeholder="Año Matriculacion" v-model="formulario.data_matricula.fecha_matriculacion" @blur="comprobarYear()" />
            <p v-if="errors.fecha" class="error">Debe rellenar este campo...</p>
          </div>
          <div class="col-lg-2 col-xl-2 col-md-5 col-sm-6 col-7 p-2 autocompletar">
            <input type="number" name="potencia" id="potencia" min="0" class="ibn_input" placeholder="Potencia" v-model="formulario.data_matricula.potencia" @blur="comprobarPotencia()" />
            <p v-if="errors.potencia" class="error">Debe rellenar este campo...</p>
          </div>
          <div class="col-lg-2 col-xl-2 col-md-2 col-sm-4 col-4 p-2 mt-2 autocompletar">
            <label class="checkbox_label" for="potenciaNoLoSe">
              <input type="checkbox" id="potenciaNoLoSe" class="checkbox" @change="noSe($event)" />
              <span class="checkbox_text ml-2">No sé</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center pt-2 pb-4">
      <div id="sixth_row_form" class="row justify-content-center pt-3 pb-2">
        <div id="boton_continuar" class="col-12">
          <button type="button" class="btn-orange center" @click="comprobarDatos()">
            Continuar
            <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
            </svg>
          </button>
          <!-- <button :disabled="!buttonEnabled()" @click="nextStepFallo()" class="btn-orange center">
            Continuar
            <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
            </svg>
          </button> -->
        </div>
      </div>
    </div>
  </fieldset>
  <cardsFormularioVue class="d-block d-sm-block d-md-none d-lg-none d-xl-none" />
  <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none mt-2">
    <button class="btn-orange center">
      Continuar
      <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
      </svg>
    </button>
    <!-- <button :disabled="!buttonEnabled()" @click="nextStepFallo()" class="btn-orange center">
      Continuar
      <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
      </svg>
    </button> -->
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";
//  docu: https://vue3-simple-typeahead-demo.netlify.app/
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import cardsFormularioVue from "./cardsFormulario.vue";

export default {
  name: "Step1FalloApi",
  components: {
    SimpleTypeahead,
    cardsFormularioVue,
  },
  store,
  computed: {
    ...mapState({
     step: (state) => state.formulario.step,
     api_token: (state) => state.api_token,
     formulario: (state) => state.formulario,
     data_matricula: (state) => state.formulario.data_matricula,
     solicitud: (state) => state.solicitud,
     preload: (state) => state.preload,
     api_url: (state) => state.api_url
   }),
    catalogo_marcas: function () {
      return this.marcas.flatMap((marcas) => [marcas.marca.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), marcas.marca.normalize("NFD").match(/[\u0300-\u036f]/g) ? marcas.marca : ""]);
    },
    catalogo_modelos: function () {
      return this.modelos.flatMap((modelos) => [modelos.modelo.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), modelos.modelo.normalize("NFD").match(/[\u0300-\u036f]/g) ? modelos.modelo : ""]);
    },
  },
  data: function () {
    return {
      codigo: "",
      maxPrestar: "",
      idMarca: "",
      marcas: [],
      modelos: [],
      years: [],
      windowWidth: window.innerWidth,
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
      }
    };
  },
  methods: {
    comprobarMarca() {
      if (this.formulario.data_matricula.marca == "" || this.formulario.data_matricula.marca == null || this.formulario.data_matricula == undefined) {
        this.errors.marca = true;
        document.getElementById("typeahead_marcas").style.border = "1px solid #ff000080";
        document.getElementById("typeahead_marcas").style.borderLeft = "7px solid #ff000080";
        return false;
      } else {
        this.errors.marca = false;
        document.getElementById("typeahead_marcas").style.border = "1px solid green";
        document.getElementById("typeahead_marcas").style.borderLeft = "7px solid green";
        return true;
      }
    },
    comprobarModelo() {
      if (this.formulario.data_matricula.modelo == "" || this.formulario.data_matricula.modelo == null) {
        this.errors.modelo = true;
        document.getElementById("typeahead_modelos").style.border = "1px solid #ff000080";
        document.getElementById("typeahead_modelos").style.borderLeft = "7px solid #ff000080";
        return false;
      } else {
        this.errors.modelo = false;
        document.getElementById("typeahead_modelos").style.border = "1px solid green";
        document.getElementById("typeahead_modelos").style.borderLeft = "7px solid green";
        return true;
      }
    },
    comprobarPotencia() {
      if (this.formulario.data_matricula.potencia == "" || this.formulario.data_matricula.potencia == null) {
        this.errors.potencia = true;
        document.getElementById("potencia").style.border = "1px solid #ff000080";
        document.getElementById("potencia").style.borderLeft = "7px solid #ff000080";
        return false;
      }else {
        this.errors.potencia = false;
        document.getElementById("potencia").style.border = "1px solid green";
        document.getElementById("potencia").style.borderLeft = "7px solid green";
        return true;
      }
    },
    comprobarYear() {
      if (this.formulario.data_matricula.fecha_matriculacion == "" || this.formulario.data_matricula.fecha_matriculacion == null) {
        this.errors.fecha = true;
        document.getElementById("fecha").style.border = "1px solid #ff000080";
        document.getElementById("fecha").style.borderLeft = "7px solid #ff000080";
        return false;
      } else {
        this.errors.fecha = false;
        document.getElementById("fecha").style.border = "1px solid green";
        document.getElementById("fecha").style.borderLeft = "7px solid green";
        return true;
      }
    },
    comprobarDatos() {
      const marca = this.comprobarMarca();
      const modelo = this.comprobarModelo();
      const potencia = this.comprobarPotencia();
      const year = this.comprobarYear();
      if (marca && modelo && potencia && year) {
        store.commit("setStepFallo",2);
      }
    },
    noSe(evento) {
      if (evento.target.checked) {
        this.formulario.data_matricula.potencia = "0";
        this.comprobarPotencia();
        document.getElementById('potencia').disabled = true;
      }else {
        this.formulario.data_matricula.potencia = null;
        this.comprobarPotencia();
        document.getElementById('potencia').disabled = false;

      }
    },
    setMarcaVehiculo(evento) {
      if (evento && evento.items && evento.items.length == 1) {
        this.formulario.data_matricula.marca = evento.items[0];
        store.commit("setMarca",evento.items[0]);
        this.marcas.forEach((item) => {
        if (item.marca == evento.items[0]) {
          this.idMarca = item.id;
          this.getModelos();
        }
      });
      }else {
        this.formulario.data_matricula.marca = null;
        store.commit("setMarca",null);
        this.formulario.data_matricula.modelo = null;
        store.commit("setModelo",null);
        this.$refs.typeAheadModelos.clearInput();
        this.idMarca = null;
        this.getModelos();
        this.comprobarModelo();
      }
      this.comprobarMarca();
    },
    setModelo(evento) {
      if (evento && evento.items && evento.items.length == 1) {
        this.formulario.data_matricula.modelo = evento.items[0];
        store.commit("setModelo",evento.items[0]);
      }else {
        this.formulario.data_matricula.modelo = null;
        store.commit("setModelo",null);
      }
      this.comprobarModelo();
    },

    getMarcas: function () {
      axios.get(this.api_url + "getMarcas").then((resp) => {
        this.marcas = resp.data.data;
      });
    },
    getModelos: function () {
      if (this.idMarca) {
        axios.get(this.api_url + "getModelosByMarca/" + this.idMarca).then((resp) => {
          this.modelos = resp.data.data;
        });
      }
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }

  },
  created: function () {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "solicitud_aceptada",
    });
    this.getMarcas();
    this.getModelos();
    store.commit("setPreload", true);
  },
  mounted: function () {
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "blanco");
    store.commit("setCheckColor2", "blanco");
    store.commit("setBarraColor2", "blanco");
    store.commit("setCheckColor3", "blanco");
    this.codigo = this.$route.params.codigo;
    // Si no viene código, redirigir al primer paso

    if (!isNaN(this.codigo) || !this.codigo == "") {
      // Buscar id solicitud
      var idSolicitud = this.codigo.slice(14);
      store.commit("setTokenEnlace", this.codigo);
      store.commit("setIdSolicitud", idSolicitud);
    }
    store.commit("setPreload", false);
    const menu = document.querySelector('#navigation_menu');
    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }
    window.addEventListener('resize', this.getWindowWidth);
  },
  watch: {
    windowWidth(value) {
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    }
  },
};
</script>
<!-- <style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none !important;
  }
}
@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: none !important;
  }
}
@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none !important;
  }
}
</style> -->
<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.slogan1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #004680;
}
.checkbox_text {
  font-size: 18px;
  vertical-align: sub;
}
.slogan2 {
  color: black;
  font-size: 30px;
  font-family: "montserrat-bold";
}

.autocompletar:deep() .simple-typeahead > input[data-v-f81ca714] {
  width: 100%;
  padding: 10px 20px;
  height: 48px;
  line-height: 22px;
  font-size: 18px;
  border-radius: 6px;
  background: white;
  border: solid 1px #c4c4c4;
  opacity: 0.6;
}

#step1 {
  height: 100%;
  .select,
  .ibn_input {
    width: 100%;
    padding: 10px 20px;
    height: 48px;
    line-height: 22px;
    font-size: 18px;
    border-radius: 6px;
    background: white;
    border: solid 1px #c4c4c4;
    opacity: 0.6;
  }
  .checkbox_label {
    display: inline;
    align-items: center;
    color: #999999;
    font-size: small;
  }
  .checkbox {
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #999999;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .checkbox:checked {
    background-image: url("../../assets/img/check-gris.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #999999;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }
  .card {
    background-color: white;
    border: none;
  }
  .verMas {
    color: #ffa500;
    font-weight: 600;
    cursor: pointer;
  }
  .feature {
    font-family: "Montserrat-Bold";
    font-size: 14px;
  }
}

@include media-breakpoint-only(xs) {
  #cards_row_form {
    margin-top: 0px !important;
  }
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
    .slogan1 {
      font-size: 17px;
    }
    .slogan2 {
      font-size: 22px;
    }
  }
}
@include media-breakpoint-only(sm) {
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}

@include media-breakpoint-only(md) {
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(lg) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }

  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(xl) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }

  #step1 {
    margin-top: 95px;

    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}
</style>
